.overlay {
  position: fixed;

  left: 0;
  top: 0;

  width: 100vw;
  height: 200vh;

  z-index: 1;

  pointer-events: none;

  opacity: 0;

  transition: opacity 1s;
}

.overlay-content {
  margin: var(--images-header-margin) var(--viewport-width-margin);
  padding: 1cm 0 1cm 0.5cm;

  display: flex;
  
  flex-direction: column;
}

.overlay-text {
  width: 86%;

  font-family: MinionPro, 'Times New Roman', Times, serif;
  font-size: var(--viewport-footer-font-size);
}

.overlay-text-right {
  width: 100%;

  padding-right: 10px;

  text-wrap: nowrap;

  align-self: flex-end;
  text-align: right;
}

@media only screen and (max-width: 1000px) {
  .overlay-content {
    margin-top: 1em;
    padding-top: 0.5em;
    padding-left: 0;
  }

  .overlay {
    position: absolute;
  }

  .overlay-text {
    width: 100%;

    font-size: 1em;
  }
}
