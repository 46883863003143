@font-face {
  font-family: MinionPro;
  src: url("assets/fonts/MinionPro-Regular.otf") format("opentype");
}

:root {
  /*
   * Viewport
   */

  /* 166px / 1920px = 0.086 */
  --viewport-width-margin: 8.6vw;

  /* 516px / 1080px = 0.477 */
  --viewport-height-margin: 47.7vh;

  /* 166px / 1920px = 0.086 */
  --viewport-footer-margin: 8.6vw;

  /* 280 / 1080px = 0.256 */
  --viewport-overlay-margin: 25.6vh;

  /* 18pt */
  --viewport-footer-font-size: 1.2em;

  /* (550px - 82px) / 1080px = 0.433 */
  --viewport-images-margin: 43.3vh; 

  /* 100px / 1080px = 0.093 */
  --viewport-images-gap: 9.3vh; 

  /*
   * Header
   */

  /* 143px / 1080px = 0.132 */
  --images-header-margin: 13.2vh;

  /* 82px / 1080px = 0.076 */
  --images-header-height: 7.6vh;

  /*
   * Images width alternative
   */

  /* 1200px / 1920px = 0.625 */
  --images-horizontal-width: 62.5vw;

  /* 675 / 1920px = 0.350 */
  --images-vertical-width: 35.0vw;

  /*
   * Images height alternative
   */

  /* 900x / 1080px = 0.833 */
  --images-horizontal-height: 83.3vh;

  /* 900x / 1080px = 0.833 */
  --images-vertical-height: 83.3vh;
}

@media only screen and (max-width: 1000px) {
  :root {
    --viewport-overlay-margin: var(--images-header-height);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: absolute;

  left: 0;
  top: 0;

  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}
