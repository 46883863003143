.image-container {
  display: flex;

  flex-direction: column;
  gap: var(--viewport-images-gap);

  padding: var(--images-header-margin) var(--viewport-width-margin) var(--images-header-height);

  transition: opacity 1s;
}

.image {
  width: var(--images-horizontal-width);
}

.image img {
  object-fit: cover;
}

.image-right {
  align-self: flex-end;
}

.image-end {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.image-horizontal {
  aspect-ratio: 4 / 3;

  width: var(--images-horizontal-width);
  /*height: var(--images-horizontal-height);*/
}

.image-vertical {
  aspect-ratio: 3 / 4;

  width: var(--images-vertical-width);
  /*height: var(--images-vertical-height);*/
}

.image-vertical img,
.image-horizontal img {
  width: 100%;
  height: 100%;
}

.image-hoverable {
  position: relative;
}

.image-hoverable .image-overlay {
  opacity: 0;

  position: absolute;

  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, .55);

  transition: opacity 1s;

  display: flex;

  justify-content: center;
  align-items: center;

  text-align: center;

  font-family: MinionPro, 'Times New Roman', Times, serif;
  font-size: min(calc(13vw / 12), 1.2em);
}

.image-hoverable:hover .image-overlay {
  opacity: 1;
}

@media only screen and (max-width: 1000px) {
  .image-container {
    padding-top: 1em;
    padding-bottom: calc(var(--images-header-margin) + var(--viewport-images-gap));
  }
  
  .image {
    width: 100%;
  }

  .image-horizontal {
    width: 100%;
  }

  .image-vertical {
    width: 100%;
  }

  .image-hoverable:hover {
    pointer-events: none;
  }

  .image-hoverable .image-overlay {
    font-size: 1em;
  }
}
