.footer {
  position: fixed;

  left: var(--viewport-footer-margin);
  right: var(--viewport-footer-margin);
  bottom: var(--images-header-height);

  padding-left: 10px;
  padding-right: 10px;

  transition: top 1s;
  transition: bottom 1s;

  --font-size: min(calc(13vw / 12), 1.2em);

  z-index: 3;
}

.footer-content {
  display: flex;

  width: 100%;

  gap: 1em;

  flex-direction: column;

  z-index: 3;
}

.footer-anchor {
  flex: 1;
  
  text-align: right;

  transition: opacity 1s;
}

.footer-anchor img {
  margin-top: auto;
  margin-bottom: auto;
  
  height: 3rem;

  object-fit: contain;
}

.footer-links {
  font-family: MinionPro, 'Times New Roman', Times, serif;
  font-size: var(--font-size);

  text-align: right;

  transition: color 1s;

  white-space: nowrap;
  text-wrap: nowrap;
}

footer p {
  margin: 0;
}

footer a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .footer {
    position: fixed;

    transition: top var(--first-image-loaded, 0s);

    transform: translate(-10px, -100%);

    top: var(--first-image-offset, calc(
      var(--images-header-margin) + var(--images-header-height) + 1rem + var(--images-header-height) +
      calc(82.7vw / 4 * 3) - 4.5rem + var(--images-header-margin)));

    bottom: unset;
  }

  .footer-scrolled {
    top: 100%;
  }

  .footer-hovered {
    top: calc(100% - 10px);
  }

  .footer-content {
    gap: 10px;

    padding-left: 10px;
    padding-right: 10px;
  }

  .footer-scrolled .footer-content {
    top: calc(100vh + 6.5rem);
  }

  .footer-anchor {
    display: flex;

    justify-content: flex-end;
    
    align-items: center;

    height: var(--viewport-images-gap);

    flex: unset;
  }
  
  .footer-links {
    font-size: 1em;
  }
}
