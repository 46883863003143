.header {
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;

  gap: 0.5rem;

  padding: var(--images-header-margin) var(--viewport-width-margin) 0;

  overflow: hidden;
}

.header-item {
  align-items: center;
}

.header-item img {
  cursor: pointer;

  transition: filter 1s;
}


.header-item-active img,
.header-item img:hover {
  filter: brightness(0%);
}

.header-item-inactive img {
  filter: none !important;
}

.header-item img {
  position: relative;

  height: var(--images-header-height);
  max-width: 100%;
  object-fit: contain;
}

.header-item img:hover .overlay {
  display: block;
}

.header[data-hovered-path="gulstenstudio"] .header-item:nth-child(1) img,
.header[data-hovered-path="atelje"] .header-item:nth-child(2) img {
  z-index: 2;
}

@media only screen and (max-width: 1000px) {
  .header-item img {
    height: unset;
  }
}
